import React, { useEffect, useState } from 'react'
import "./CircularGraph.css"
import '../../../src/App.css'

export const CircularGraph = ({room,skills,optionalSkills, newInterviewFeedbackPointsImg}) => {

    const newInterviewFeedbackPoints = (rating) => {
        if(rating >= 0 && rating < 3){
            return "Low Performer"
        }
        else if(rating >=3 & rating < 5){
            return "Substandard Performer"
        }
        else if(rating == 5){
            return "Moderate Performer"
        }
        else if(rating == 6){
            return "Satisfactory Perfomer"
        }
        else if(rating == 7){
            return "Competent Perfomer"
        }
        else if(rating==8){
            return "Outstanding Performer"
        }
        else if(rating == 9 || rating == 10){
            return "Exceptional Performer"
        }
    }
    
    const newInterviewFeedbackPointsColor = (rating) => {
        if(rating >= 0 && rating < 3){
            return "cr"
        }
        else if(rating >=3 & rating < 5){
            return "cr"
        }
        else if(rating == 5){
            return "cy"
        }
        else if(rating == 6){
            return "cy"
        }
        else if(rating == 7){
            return "cg"
        }
        else if(rating==8){
            return "cg"
        }
        else if(rating == 9 || rating == 10){
            return "cg"
        }
    }
    
    return (
        <div className='circular-graph-wrapper'>
            <div className='circular-graph-header font-style-header-circular'>
                Mandatory Skills
            </div>
            <div className='mandatory-skills-graph-wrapper'>
                <div className='row1 d-flex flex-wrap'>
                    {skills && Object.keys(skills).map(key =>{
                        return (
                            <div className='individual-graph-wrapper'>
                                <img className='graph-img' src={newInterviewFeedbackPointsImg(skills[key])} alt="80" />
                                <p className='graph-title' style={{ "margin": "0px" }}>{key.length>20 ? key.substring(0,20)+'...':key}</p>
                                <p style={{ "margin": "0px" }}>
                                    <span className={`graph-descp-comment ${newInterviewFeedbackPointsColor(skills[key])}`}> {newInterviewFeedbackPoints(skills[key])}</span>
                                </p>
                            </div>
                        )
                    })}
                </div>
            </div>
            {optionalSkills && Object.keys(optionalSkills).length>0 && 
                <div className='optional-skills-wrapper'>
                    {/* <div className='pagebreak'></div> */}
                    <div className='circular-graph-header optional-graph-header font-style-header-circular'>
                        Optional Skills
                    </div>
                    <div className='mandatory-skills-graph-wrapper'>
                        <div className='row1 d-flex flex-wrap'>
                            {optionalSkills && Object.keys(optionalSkills).map(key =>{
                                return (
                                    <div className='individual-graph-wrapper'>
                                        <img className='graph-img' src={newInterviewFeedbackPointsImg(optionalSkills[key])} alt="80" />
                                        <p className='graph-title' style={{ "margin": "0px" }}>{key.length>20 ? key.substring(0,20)+'...':key}</p>
                                        <p style={{ "margin": "0px" }}>
                                            <span className={`graph-descp-comment ${newInterviewFeedbackPointsColor(optionalSkills[key])}`}> {newInterviewFeedbackPoints(optionalSkills[key])}</span>
                                        </p>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            
            }
            <div className='pagebreak'></div>
        </div>
    )
}
