import React from 'react'
import "./DetailedFeedBack.css"
import '../../../src/App.css'
import { Grid } from '@mui/material';
import mandatoryskillheader from "../../images/mandatory-skill-header.svg";
export const DetailedFeedBack = ({room,skillComments,optionalSkillComments,skills,optionalSkills}) => {
    return (
        <Grid container className='feedback-wrapper'>
            
            <Grid container justifyContent="center" className='feedback-header'>
                Detailed Feedback
            </Grid>
            <Grid className='mandatory-skills-wrapper'>
                <Grid container justifyContent="center" textAlign="center" className='font-style-header-detailed title-600'>
                    Mandatory Skills
                </Grid>
                <div className='mandatory-skills-outer-wrapper'>
                    <div className='font-style-header-detailed title-1' >
                        Mandatory Skills
                    </div>
                </div>
                <div className='mandatory-skills-content font-style-detailed-text'>
                    {skillComments &&  Object.keys(skillComments).map(key =>{
                        return (
                            <p style={{ "margin": "10px 0", width: "90%" }}>
                                <strong style={{overflowWrap:'anywhere'}}>{key}: ({skills[key]})</strong><br />
                                <span style={{overflowWrap:'anywhere'}}>{skillComments[key]}</span>    
                            </p>
                        )
                    })}
                    
                </div>
            </Grid>
            {optionalSkillComments && 
            <>
                <Grid justifyContent="space-between" className='opyional-skills-wrapper break-stop'>
                    <Grid container justifyContent="center" textAlign="center" alignItems="center" className='optional-skills-outer-wrapper-600 font-style-header-detailed'>
                        Optional Skills
                    </Grid>
                    <div className='opyional-skills-content font-style-detailed-text'>
                        {optionalSkillComments &&  Object.keys(optionalSkillComments).map(key =>{
                            return (
                                optionalSkillComments[key]!=null ? (
                                    <p style={{ "margin": "10px 0", width: "90%" }}>
                                        <strong style={{overflowWrap:'anywhere'}}>{key}: ({optionalSkills[key]})</strong><br />
                                        <span style={{overflowWrap:'anywhere'}}>{optionalSkillComments[key]}</span>
                                    </p>

                                ): (
                                    <></>
                                )
                            )
                        })}
                        
                    </div>
                    <div className='optional-skills-outer-wrapper'>
                        <div className='optional-skills-header font-style-header-detailed'>
                            Optional Skills
                        </div>
                    </div>
                </Grid>
            </>
            }
            
            <div className='pagebreak'></div>
        </Grid >
        
    )
}
