// import { Pie } from "react-chartjs-2";
import { Doughnut } from 'react-chartjs-2';


import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { useEffect } from 'react';

ChartJS.register(ArcElement, Tooltip, Legend);



export const MultipleSeriesPieChart = ({ backGroundColor, skillsRating }) => {
    
    useEffect(()=>{
        if(skillsRating){
            console.log("skillsRating",skillsRating);
        }
    },[skillsRating]);
    
    // var config = {
    //     type: 'doughnut',

    //     options: {
    //         responsive: true,

    //         plugins: {
    //             legend: {
    //                 display: false,
    //             },
    //             datalabels: {
    //                 // display: false,
    //                 // align: 'bottom',
    //                 // backgroundColor: '#ccc',
    //                 // borderRadius: 3,
    //                 // font: {
    //                 //     size: 18,
    //                 // },
    //             },
    //         },
    //     }
    // }
    const options = {
        plugins: {
            legend: {
                display: false
            },
            datalabels: false
        },
        responsive: true,
        maintainAspectRatio: false,
    }
    var data = {
        // labels: ['Company Policy', 'Working Hours'],
        datasets: [
            {
                data: [
                    skillsRating?skillsRating[0]:0, 100-(skillsRating?skillsRating[0]:0)
                ],
                backgroundColor: backGroundColor
            }, {
                data: [
                    skillsRating?skillsRating[1]:0, 100-(skillsRating?skillsRating[1]:0)
                ],
                backgroundColor: backGroundColor
            }, {
                data: [
                    skillsRating?skillsRating[2]:0, 100-(skillsRating?skillsRating[2]:0)
                ],
                backgroundColor: backGroundColor
            }]
    }
    return (
        <Doughnut data={data} options={options} />
    )
}