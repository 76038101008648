const hostname= location.hostname;
let apiUrlCheck= ""
let imageUrlCheck= ""

if(hostname == (process.env.REACT_APP_HOSTNAME_LOCAL)){
    apiUrlCheck = process.env.REACT_APP_API_URL_LOCAL;
    imageUrlCheck = process.env.REACT_APP_IMAGE_URL_LOCAL;
}
else if(hostname == (process.env.REACT_APP_HOSTNAME_DEV)){
    apiUrlCheck = process.env.REACT_APP_API_URL_DEV;
    imageUrlCheck = process.env.REACT_APP_IMAGE_URL_DEV;
}
else if(hostname == (process.env.REACT_APP_HOSTNAME_PROD)){
    apiUrlCheck = process.env.REACT_APP_API_URL_PROD;
    imageUrlCheck = process.env.REACT_APP_IMAGE_URL_PROD;
}

export const apiUrl = apiUrlCheck;
export const imageUrl = imageUrlCheck;