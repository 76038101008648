import React from "react";
import Feedback from '../src/page/feedback';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";

function App() {

  return (
    <Router>
      <Routes>
        <Route path="/:uuid" element={<Feedback/>} />
      </Routes>
    </Router>
  );

  
}


export default App;
