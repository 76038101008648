
import { Radar } from "react-chartjs-2";
import {
    Chart as ChartJS,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
} from 'chart.js';
import { useEffect, useState } from "react";

ChartJS.register(
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend
);

export const Rad = ({ labels, ratings }) => {

    const [graphData, setGraphData] = useState([
        [0,0],
        [0,0]
    ]);

    useEffect(()=>{
        if(ratings){
            // console.log("ratings",[JSON.parse(ratings[0]),JSON.parse(ratings[1])]);
            setGraphData([JSON.parse(ratings[0]),JSON.parse(ratings[1])]);
        }
    },[ratings]);

    const data = {
        labels: labels??[],
        datasets: [
            {
                label: 'Avg. Global Skills',
                data: graphData[0],
                backgroundColor: 'rgba(17, 178, 184, 0.4)',
                borderColor: 'rgba(255, 99, 132, 1)',
                borderWidth: 1,
            },
            {
                label: 'Candidate Skills',
                data: graphData[1],
                backgroundColor: 'rgba(19, 63, 125, 0.4)',
                borderColor: 'rgba(255, 99, 132, 1)',
                borderWidth: 1,
                strokeColor: "#000000",
                pointColor: "#000000",
                pointHighlightStroke: "#000000",
            },
        ],
    };

    const options = {
        plugins: {
            legend: {
                display: false
            }
        },
        tooltips: {
            enabled: false
        },
        scales: {
            r: {
                max: 10,
                min: 0,
                ticks: {
                    stepSize: 1,
                    textStrokeColor: 'black',
                    color: 'black',
                    // backdropColor: 'rgb(47, 56, 62,)',
                    display: true
                },
                angleLines: {
                    color: ' rgba(155, 155, 155, 0.1)'
                },
                pointLabels: {
                    color: '#000000',
                    font: {
                        size: 12,
                        family: 'Manrope',
                        style: 'normal',
                        weight: 400
                    }
                },
                grid: {
                    color: "#9B9B9B",
                    circular: true
                },
                gridLines: {
                    color: "#9B9B9B",
                },
            },
        }
    }

    return <Radar data={data} options={options} />;
}
