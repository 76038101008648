import React, { useState } from 'react'
import "./CodeSnippet.css"
import img1 from "../../images/screenshot1.svg"
import img2 from "../../images/screenshot2.svg"
import img3 from "../../images/screenshot3.svg"
import imgframe from "../../images/imgframe.svg"
import fillimg from "../../images/fillimg.svg"
import { Grid } from '@mui/material'
import { useEffect } from 'react'
import { imageUrl } from '../../utils/urls'

export const CodeSnippet = ({room}) => {

    const [code,setCode] = useState(null);
    const [attachments,setAttachments] = useState(null);

    useEffect(()=>{
        if(room){
            setCode(room.interview_feedback.code_text);
            
            if(room.interview_feedback.attachments){
                setAttachments(room?.interview_feedback?.attachments?.split(','))
            }
        }        
    },[room]);

    return (
        <>
            
            {(attachments && Object.keys(attachments).length >0 ) && 
            
            <>
                <Grid item className='code-snippet-wrapper' style={{backgroundColor: 'transparent'}}>

                    <Grid className='code-snippet-header'>
                        <span className='code-snippet-span' >Attachments</span>
                    </Grid>
                    <div className='screenshots-container'>
                        {attachments.map((val, index) => (
                            <div className="col-4 row m-0 px-0 screenshot-wrapper">
                                <img src={imageUrl + "/attachments/" + val} width="100%" alt="" />
                            </div>
                        ))}
                    </div>
                </Grid>
                
                <div className='pagebreak'></div>
            </>
            }
            
            {code && 
            <Grid item className='code-snippet-wrapper'>

                <Grid className='code-snippet-header'>
                    <span className='code-snippet-span' >Code Snippet</span>
                </Grid>
                <Grid className='code-content'>
                    <pre className='code-content-pre'>
                        <code className='code font-styling-text' style={{whiteSpace: 'pre-wrap'}}>
                            {code}
                        </code>
                    </pre>
                </Grid>
            </Grid>
            }

            <Grid className='final-remark-wrapper'>
                <Grid container justifyContent="center" textAlign="center" className='font-style-header-code-snippet final-remark-header-600'>
                    Final Remarks
                </Grid>
                <div className='vertical-left-header' >
                    <div className='font-style-header-code-snippet final-remark-header' >
                        Final Remarks
                    </div>
                </div>
                <div className='final-remark-content'>
                    <p className=' font-styling-text'>
                        {room?.interview_feedback?.final_remarks}
                    </p>
                </div>
            </Grid>

            {(room && room.screenshots && Object.keys(room.screenshots).length >0 ) && 
            
            <>
                <Grid item className='code-snippet-wrapper' style={{backgroundColor: 'transparent'}}>

                    <Grid className='code-snippet-header'>
                        <span className='code-snippet-span' >Screenshots</span>
                    </Grid>
                    <div className='screenshots-container'>
                        {room && room.screenshots.map((val, index) => (
                            <div className="col-3 row m-0 px-0 screenshot-wrapper">
                                <img src={imageUrl + "/screenshots/" + val.image_url} width="100%" alt="" />
                            </div>
                        ))}
                    </div>
                </Grid>
                
                {/* <div className='pagebreak'></div> */}
            </>
            }

            {/* {(room && room.screenshots && Object.keys(room.screenshots).length >0 ) && 
            <>
                <div className='screenshots-wrapper'>
                    <div className='screenshots-header'>
                        <span className='code-snippet-span'>Screenshots</span>
                    </div>
                    <div className='screenshots-container'>
                        {room && room.screenshots.map((value,index)=>{
                            return (
                                <div className='screenshot-wrapper'>
                                    <img src={imageUrl + '/screenshots/' + value.image_url} className="fill-img1"></img>
                                    <img src={imgframe} alt="Image1" className="screenshots"></img>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </>
            } */}
            <div className='footer'>
                <img className='footer-logo' src='https://incruiter-media.s3.ap-south-1.amazonaws.com/client1/emailTemplates/file-1657200036372-806435886.png' alt='Logo' />
            </div>
        </>
    )
}
