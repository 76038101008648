import React, { useEffect, useState, useRef } from 'react'
import "./Report.css"
import arrow from "../../images/arrow.png"
import { Rad } from "../RadarChart"
import percent0 from "../../images/percent0.svg"
import vectortr from "../../images/Vectortr.svg"
import vectortl from "../../images/Vectortl.svg"
import vectorb from "../../images/Vectorb.svg"
import line1 from "../../images/line1.svg"
import line2 from "../../images/line2.svg"
import line3 from "../../images/line3.svg"
import line1b from "../../images/line1b.svg"
import line2b from "../../images/line2b.svg"
import line3b from "../../images/line3b.svg"
import logo from "../../images/logo.svg"
import Poor from "../../images/Poor.svg"
import Good from "../../images/Good.svg"
import Average from "../../images/Average.svg"
// import MaleAvatar from "../../images/MaleAvatar.svg"
import GroupedprofileImg from "../../images/GroupedprofileImg.svg"
import MaleAvatar from "../../images/male-avatar.svg"
import SuspectedImage from "../../images/suspected-image.svg"
import FeedbackRating1 from "../../images/feedback-rating1.svg"
import FeedbackRating2 from "../../images/feedback-rating2.svg"
import FeedbackRating3 from "../../images/feedback-rating3.svg"
import RecommendedRing from "../../images/recommented-ring.svg"
import NotRecommendedRing from "../../images/not-recommented-ring.svg"
import CausiouslyRecommendedRing from "../../images/causiously-recommented-ring.svg"
import ProfileTabVeiw from "../../images/PrfileTabVeiw.svg"
import ProfileMobileVeiw from "../../images/ProfileMobileVeiw.svg"
import { MultipleSeriesPieChart } from "../MultipleSeriesPieChart"
import Arrow from "../../images/Arrow.svg"
import { SpeedoMeterChart } from "../SpeedoMeterChart"
import { Grid } from '@mui/material'
import { imageUrl } from '../../utils/urls'
// import { savePDF } from "@progress/kendo-react-pdf";



export const Report = ({ room, roomDetails, optionalSkills, skills, newInterviewFeedbackPointsImg, getRatingFromOtherSkills, otherSkills }) => {
    // const [isDownload, setIsDownload] = useState(false);
    const [isDevice, setIsDevice] = useState("Desktop");
    const [technicalSkills, setTechnicalSkills] = useState(0);
    const [jobSkills, setJobSkills] = useState(0);
    const [softSkills, setSoftSkills] = useState(0);


    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth >= 900) {
                setIsDevice("Desktop")
            } else if (
                window.innerWidth < 900 && window.innerWidth >= 600
            ) {
                setIsDevice("Tab")
            } else {
                setIsDevice("Mobile")
            }
        }
        window.addEventListener('resize', handleResize)


        return () => window.removeEventListener('resize', handleResize)
    }, [])

    useEffect(() => {
        if (room) {
            document.title = room?.room?.candidate_name + ' - Feedback Report';

            var technicalSkillsLengthTmp = Object.keys(skills).length;
            var technicalSkillsTmp=0;
            Object.keys(skills).map((value,index)=>{
                technicalSkillsTmp += parseInt(skills[value]);
            })

            if(technicalSkillsLengthTmp > 0){
                setTechnicalSkills(Math.round(technicalSkillsTmp/technicalSkillsLengthTmp));
            }

            var jobSkillsLengthTmp = Object.keys(optionalSkills).length;
            var jobSkillsTmp=0;
            Object.keys(optionalSkills).map((value,index)=>{
                jobSkillsTmp += parseInt(optionalSkills[value]);
            })
            // alert(jobSkillsLengthTmp);
            if(jobSkillsLengthTmp>0){
                setJobSkills(Math.round(jobSkillsTmp/jobSkillsLengthTmp));
            }

            var softSkillsLengthTmp = 3;
            var softSkillsTmp=0;
            softSkillsTmp += parseInt(getRatingFromOtherSkills('Confidence',otherSkills['Confidence']));
            softSkillsTmp += parseInt(getRatingFromOtherSkills('Communication Skills',otherSkills['Communication Skills']));
            softSkillsTmp += parseInt(getRatingFromOtherSkills('Pressure handling',otherSkills['Pressure handling']));

            if(softSkillsLengthTmp>0){
                setSoftSkills(Math.round(softSkillsTmp/softSkillsLengthTmp));
            }
        }
    }, [room]);
    // const inputRef = useRef(null)

    // useEffect(() => {
    //     if (isDownload) {
    //         let element = inputRef.current;
    //         savePDF(element, {
    //             paperSize: "auto",
    //         });
    //         setIsDownload(false);
    //     }
    // }, [isDownload]);

    const handlePrint = () => {
        console.log("click")
        window.print();
        // setIsDownload(true)
    }

    return (
        <div >
            <div className='header'>
                <Grid className='img-div'>
                    <a href="https://incruiter.com">
                        <img className='img-logo' src={logo} alt='Logo' />
                    </a>
                </Grid>
                <button className='btn btn-info' onClick={handlePrint}>Print</button>
            </div>
            <div className='information-header'>
                <div className='ih-div d-flex align-items-center justify-content-start'>
                    <div className="ih-img">
                        {
                            room?.room?.interview_feedback?.default_screenshot != null ? (
                                <>
                                    <img className='candidate-avatar' src={`${imageUrl}/screenshots/${room?.room?.interview_feedback?.default_screenshot?.image_url}`} alt='Male Avatar' />
                                </>
                            ):
                            (
                                <>
                                    {room?.room?.screenshots?.length > 0 ?
                                    (<>
                                        <div className="col-md-4 col-12 mt-2 mb-2 d-flex align-items-center justify-content-center text-center flex-column candidate-avatar">
                                            <img src={`${imageUrl}/screenshots/${room?.room?.screenshots[0]?.image_url}`} width="100%" alt="" />
                                        </div>
                                    </>)
                                    : (
                                        <>
                                            <img className='candidate-avatar' src={MaleAvatar} alt='Male Avatar' /> 
                                        </>
                                    )}
                                </>
                            )
                        }

                    </div>
                    <div className='ih-basic-info'>
                        <span>#IN00{room?.room?.reference_id}</span>
                        <p>{room?.room?.candidate_name}</p>
                        <p>{roomDetails?.job_title}</p>
                        <p>Experience: {room?.room?.candidate_experience}</p>
                        <span>{roomDetails?.candidate_contact} | {roomDetails?.candidate_email}</span>
                    </div>
                </div>
                <div className='ih-result-div'>
                    {room?.room?.interview_feedback?.rating && (
                        ((parseInt(room.room.interview_feedback.rating) < 5)) ?
                            <div className='d-flex justify-content-center' style={{flexDirection:'column',alignItems:'flex-end'}}>
                                <img src={FeedbackRating1} className='feedback-rating' />
                                <div style={{paddingRight:"30px",paddingTop:"10px"}}>
                                    <div className='overall-rating-center'>
                                        <div style={{textAlign:"center"}}>
                                            <b>{room.room.interview_feedback.rating}</b>
                                            <p style={{margin:"0"}}>/10</p>
                                        </div>
                                    </div>
                                    <img src={NotRecommendedRing} className='feedback-rating-ring' style={{width:'120px'}} />
                                </div>
                            </div>
                            :
                            <></>
                    )}

                    {room?.room?.interview_feedback?.rating && (
                        ((parseInt(room.room.interview_feedback.rating) == 5) || (parseInt(room.room.interview_feedback.rating) == 6)) ?
                            <div className='d-flex justify-content-center' style={{flexDirection:'column',alignItems:'flex-end'}}>
                                <img src={FeedbackRating2} className='feedback-rating' />
                                <div style={{paddingRight:"30px",paddingTop:"10px"}}>
                                    <div className='overall-rating-center'>
                                        <div style={{textAlign:"center"}}>
                                            <b>{room.room.interview_feedback.rating}</b>
                                            <p style={{margin:"0"}}>/10</p>
                                        </div>
                                    </div>
                                    <img src={CausiouslyRecommendedRing} className='feedback-rating-ring' style={{width:'120px'}} />
                                </div>
                            </div>
                            :
                            <></>
                    )}

                    {room?.room?.interview_feedback?.rating && (
                        ((parseInt(room.room.interview_feedback.rating) > 6)) ?
                            <div className='d-flex justify-content-center' style={{flexDirection:'column',alignItems:'flex-end'}}>
                                <img src={FeedbackRating3} className='feedback-rating' />
                                <div style={{paddingRight:"30px",paddingTop:"10px"}}>
                                    <div className='overall-rating-center'>
                                        <div style={{textAlign:"center"}}>
                                            <b>{room.room.interview_feedback.rating}</b>
                                            <p style={{margin:"0"}}>/10</p>
                                        </div>
                                    </div>
                                    <img src={RecommendedRing} className='feedback-rating-ring' style={{width:'120px'}} />
                                </div>
                            </div>
                            :
                            <></>
                    )}


                    {room?.room?.candidate_verification ==1 && room?.room?.interview_feedback?.digio_face_match < 60 && 
                        <img src={SuspectedImage} className='suspected-image' />
                    }
                </div>
            </div>
            <div className='report-wrapper'>

                <Grid container justifyContent='center' className='candidate-overall-skills'>
                    <Grid item xs={11} sm={11} md={6} lg={6} className='candidate-skills-wrapper'>
                            <span className='candidate-skills-heading'>Candidate vs. Global Skills</span>
                            <div>
                                <div className='candidate-skills' style={{ minHeight: "300px" }}>
                                    <Rad labels={room?.room?.graphs?.avg_global_graph?.labels} ratings={room?[room?.room?.graphs?.avg_global_graph?.datasets[0].data,room?.room?.graphs?.avg_global_graph?.datasets[1].data]:null} />
                                </div>
                                <div className="radar-graph-labels-main" style={{transform:'matrix(-1, 0, 0, 1, 0, 0)'}}>
                                        <div className="radar-graph-label">
                                            <span className='radar-graph-label-color' style={{backgroundColor:'rgba(19, 63, 125, 1)'}}></span>
                                            <span className='radar-graph-label-name'>Candidate</span>
                                        </div>
                                        <div className="radar-graph-label">
                                            <span className='radar-graph-label-color' style={{backgroundColor:'rgba(17, 178, 184, 1)'}}></span>
                                            <span className='radar-graph-label-name'>Global</span>
                                        </div>
                                    </div>
                            </div>
                    </Grid>
                    <Grid item xs={12} sm={11} md={6} lg={6} className='overAll-skills'>
                        <div className='overAll-skills-header'>
                            <span className='overAll-skills-span font-style-header'>Overall skills</span>
                        </div>
                        <div className='toplayer-ellip' style={{ paddingTop: "20px" }}>
                            <Grid container justifyContent='center' >
                                <Grid item xs={5} sm={3.5} md={3} style={{ textAlign: 'center' }}>
                                    <img className='toplayer-ellip-img' src={technicalSkills ? newInterviewFeedbackPointsImg(technicalSkills):percent0} alt="ellipse" />
                                    <div className='toplayer-ellip-title font-style-percent' style={{ "margin": "0px", width: "100%" }}>Mandatory Skills</div>
                                    <p className='toplayer-ellip-percent font-style-percent' style={{ "margin": "0px", justifyContent: 'center' }}>
                                        {technicalSkills ? technicalSkills + "0" : "0"}%
                                    </p>
                                </Grid>
                            </Grid>
                            <div className='vector-wrapper'>
                                <div className='vector-tl-div'>
                                    <img className='vector-tl' src={vectortl} alt="vectortl"></img>
                                    <img className='vector-tl' src={vectortr} alt="vectortr"></img>
                                </div>
                            </div>
                        </div>
                        <Grid container justifyContent='center' className='secondlayer-ellip'>
                            <Grid item xs={11} sm={8} md={10} lg={9}>
                                <Grid container justifyContent='space-between'>
                                    <Grid item xs={5} sm={5} md={4} lg={4} style={{ textAlign: 'center' }}>
                                        <img className='toplayer-ellip-img' src={jobSkills ? newInterviewFeedbackPointsImg(jobSkills):percent0} alt="ellipse" />
                                        <div className='toplayer-ellip-title font-style-percent' style={{ "margin": "0px" }}>Optional Skills</div>
                                        <p className='toplayer-ellip-percent font-style-percent' style={{ "margin": "0px" }}>
                                            {jobSkills ? jobSkills + "0": "0"}%
                                        </p>
                                    </Grid>
                                    <Grid item xs={2} sm={2} md={2} lg={4} className='vector-center-div'>
                                        <img className='vector-center' src={vectorb} alt="vectorb"></img>
                                    </Grid>
                                    <Grid item xs={5} sm={5} md={4} lg={4} style={{ textAlign: 'center' }}>
                                        <img className='toplayer-ellip-img' src={softSkills ? newInterviewFeedbackPointsImg(softSkills):percent0}  alt="ellipse" />
                                        <p className='toplayer-ellip-title font-style-percent' style={{ "margin": "0px" }}>Soft Skills</p>
                                        <p className='toplayer-ellip-percent font-style-percent' style={{ "margin": "0px" }}>
                                            {softSkills ? softSkills + "0" : "0"}%
                                        </p>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                
                <div className='pagebreak'></div>
                <Grid container justifyContent='space-between' marginTop={10}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <div className='soft-skills-wrapper'>
                            <div className='soft-skills-div'>
                                <span className='soft-skills-span'>Soft Skills</span>
                            </div>
                            <Grid container className='soft-skills-graph-wrapper' paddingTop={5} >
                                <div className='soft-skills' style={{ position: "relative", display: "flex" }}>
                                    <div className='chart-wrapper'>
                                        <MultipleSeriesPieChart skillsRating={[otherSkills?getRatingFromOtherSkills('Confidence',otherSkills['Confidence'])*10:0,otherSkills?getRatingFromOtherSkills('Communication Skills',otherSkills['Communication Skills'])*10:0,otherSkills?getRatingFromOtherSkills('Pressure handling',otherSkills['Pressure handling'])*10:0]} backGroundColor={[
                                            "#133F7D",
                                            "rgba(19, 63, 125, 0.5)",
                                        ]} />
                                    </div>
                                    <div className='rating-div'>
                                        <div className='marking-rating-name' style={{ marginTop: "28px" }}>
                                            <span style={{ "margin": "0px" }}>Confidence</span>
                                            <p style={{ "margin": "0px", "marginTop": "-10px"}}><span className='marking-rating-span'>{otherSkills? otherSkills['Confidence'] : ''}</span></p>
                                        </div>
                                        <div className='marking-rating-name' style={{ marginTop: "15px" }}>
                                            <span style={{ "margin": "0px" }}>Communication</span>
                                            <p style={{ "margin": "0px", "marginTop": "-10px" }}><span className='marking-rating-span'>{otherSkills? otherSkills['Communication Skills'] : ''}</span></p>
                                        </div>
                                        <div className='marking-rating-name' style={{ marginTop: "15px" }}>
                                            <span style={{ "margin": "0px" }}>Pressure Handling</span>
                                            <p style={{ "margin": "0px", "marginTop": "-10px" }}><span className='marking-rating-span'>{otherSkills? otherSkills['Pressure handling'] : ''}</span></p>
                                        </div>
                                    </div>
                                    <div>
                                        <img className='m3-img' src={line3} ></img>
                                        <img className='m2-img' src={line2} ></img>
                                        <img className='m1-img' src={line3} ></img>
                                    </div>
                                </div>
                            </Grid>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <div className='phychological-skills-wrapper'>
                            <div className='phychological-skills-div'>
                                <span className="psychological-skills-span">Analytical Skills</span>
                            </div>
                            <Grid container className='soft-skills-graph-wrapper' paddingTop={5} >
                                <div className='soft-skills' style={{ position: "relative", display: "flex" }}>
                                    <div className="chart-wrapper">
                                        <MultipleSeriesPieChart skillsRating={[otherSkills?getRatingFromOtherSkills('Approach & Attitude',otherSkills['Approach & Attitude'])*10:0,otherSkills?getRatingFromOtherSkills('Problem solving',otherSkills['Problem solving'])*10:0,otherSkills?getRatingFromOtherSkills('Result oriented',otherSkills['Result oriented'])*10:0]}  backGroundColor={["rgba(17, 178, 184, 0.8)", "rgba(17, 178, 184, 0.5)"]} />
                                    </div>
                                    <div className='rating-div' style={{ marginLeft: "15px" }}>
                                        <div className='marking-rating-name' style={{ marginTop: "26px", }}>
                                            <span style={{ "margin": "0px" }}>Approach & Attitude</span>
                                            <p style={{ "margin": "0px", "marginTop": "-10px" }}><span className='marking-rating-span'>{otherSkills?otherSkills['Approach & Attitude']:''}</span></p>
                                        </div>
                                        <div className='marking-rating-name'  style={{ marginTop: "15px" }}>
                                            <span style={{ "margin": "0px" }}>Problem Solving</span>
                                            <p style={{ "margin": "0px", "marginTop": "-10px" }}><span className='marking-rating-span'>{otherSkills?otherSkills['Problem solving']:''}</span></p>
                                        </div>
                                        <div className='marking-rating-name'  style={{ marginTop: "15px" }}>
                                            <span style={{ "margin": "0px" }}>Result Orientation</span>
                                            <p style={{ "margin": "0px", "marginTop": "-10px" }}><span className='marking-rating-span'>{otherSkills?otherSkills['Result oriented']:''}</span></p>
                                        </div>
                                    </div>
                                    <div>
                                        <img className='m3-img2' src={line1b}></img>
                                        <img className='m2-img2' src={line2b} ></img>
                                        <img className='m1-img2' src={line3b} ></img>
                                    </div>
                                </div>
                            </Grid>
                        </div>
                    </Grid>
                </Grid >
                <div className='pagebreak'></div>
            </div >
        </div >
    )
}
